:root {
  --black: #000c14;
  --teal: #233d4d;
  --tan: #ffecd1;
  /* --light-orange: #ff7d00;/ */
  --light-orange: #ff4b00;
  --dark-orange: #78290f;
  --green: #a1c181;
  --green-darker: #619b8a;
  --accent-yellow: #fcca46;
  font-family: "Bevan", cursive;
  /* font-family: "Poly", serif; */
  /* font-family: "BIZ UDMincho", serif; */
  /* font-family: 'Castoro Titling', cursive;
  font-family: 'Libre Caslon Display', serif;
  font-family: 'Lobster Two', cursive;
  font-family: 'Xanh Mono', monospace; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* background-image: url("./img/trees.jpg");*/
}

.promo-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--light-orange); 
  color: var(--tan); /* Dark text */
  font-family: "BIZ UDMincho", serif;
  font-size: 1.1rem;
  text-align: center;
  padding: 20px 0;
  z-index: 1050; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* margin-bottom: 0px !important; */
}

.promo-banner h2 {
  margin: 0;
}

.promo-banner p {
  margin: 10px 0;
}

.promo-banner button {
  margin-top: 10px;
}

/* Push main content down so it doesn’t get hidden under the banner */
.main-content {
  margin-top: 100px; 
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 6%;

  position: absolute;
  top: -20px;
  z-index: 3;
}

.logoimg {
  height: 150px;
  width: 350px;
}

.hooks-main {
  margin: auto;
  font-family: "Bevan", cursive;
  font-size: 2rem;
  position: relative;
  background: transparent;
  z-index: 0;
  color: var(--light-orange) !important;
  text-shadow: #000c14 4px 4px 3px;
}

.hooks-main:before {
  content: attr(title);
  position: absolute;
  -webkit-text-stroke: 0.05em white;
}

/* .nav-brand {
  font-family: "Bevan", cursive !important;
  font-size: 2.2rem;
  position: relative;
  background: transparent;
  z-index: 0;
  color: white !important;
} */

/* .nav-brand:before {
  content: attr(title);
  position: absolute;
  -webkit-text-stroke: 0.05em var(--light-orange);
}

.nav-brand:after {
  content: attr(title);
  position: absolute;
  -webkit-text-stroke: 0.25em black;
  left: 0;
  z-index: -2;
} */

.nav-main {
  background-color: var(--teal) !important;
  z-index: 2;
  font-family: "Poly", serif;
  min-height: 100px;
  height: auto;
}

/* .me-auto {
  margin-left: 700px;
} */

#basic-navbar-nav {
  justify-content: end !important;
}

.navbar-toggler {
  margin-right: 0px !important;
}

.nav-links {
  margin-right: 0px !important;
}

.link {
  font-size: 1.5rem !important;
  font-weight: 800;
  font-family: "Poly", serif !important;
  color: white !important;
  margin-right: 10px;
}

.link:hover {
  text-shadow: black 2px 3px 2px;
  color: var(--light-orange) !important;
}

.full-page-section {
  z-index: 1;
  width: 99vw; /* 100% of viewport width */
  /* height: 96vh; 100% of viewport height */
  height: auto;
  background-color: #ffecd1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tiles-container {
  display: flex;
  justify-content: space-around;
  gap: 30px;
}

.container {
  /* justify-content: space-between !important; */
}

.tile {
  width: 300px;
  border: 2px solid grey;
  color: #000c14;
  padding: 30px;
  background-color: #ffecd1;
  font-family: "Poly";
}

.demo-display-parent {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
}

.demo-main-section {
  display: flex;
  width: 100%;
  height: 90%;
  /* If you want a background color and border */
  /* background-color: var(--dark-orange); */
  /* border: var(--dark-orange) 2px solid; */
  padding: 20px;
  gap: 20px;
}

.demo-left-section {
  width: 70%;
  height: 100%;
  border: 2px black solid;
  background-color: var(--tan);
}

.demo-right-section {
  width: 30%;
  height: 100%;
  border: 2px black solid;
  background-color: var(--tan);
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-items: flex-end; */
  justify-content: end;
  text-align: center;
}

.demo-right-child {
  height: 20%;
  width: 100%;
  /* border: 2px solid black */
}

.demo-right-child:hover {
  box-shadow: 2px 3px 4px black;
  background-color: rgb(206, 172, 126);
}

.demo-child-image {
}

.demo-child-header {
  font-size: 1.6rem;
  padding-top: 8px;
  /* font-family: "Poly", serif; */
  font-family: "Bevan", cursive;
  text-transform: uppercase;
}

.coming-soon {
  width: 100vw;
  height: 100vh;
  background-color: var(--tan);
}

.coming-soon-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
  height: 500px;
}

.coming-soon-header {
  font-family: 'Lobster Two', cursive;
  transform: rotate(-5deg);
  height: 100px;
  color: var(--light-orange);
	font-size: 3.8rem;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;

}

.cs-line {
  margin-top:5px;
  height: 5px;
  width: 80%;
  border-bottom: 2px black solid
}

.coming-soon-text {
  font-family: "BIZ UDMincho", serif;
  width: 50%;
  font-size: 1.5rem;
}

.button {
  text-decoration: none;
  color: #78290f;
  border: 2px solid var(--tan);
  box-shadow: -3px 3px 5px #000c14;
  font-weight: 800;
  font-family: "Poly", serif;
}

.button:hover {
  text-decoration: none;
  transform: scale(1.1);
  color: #78290f;
}

/*.call-bar*/
.scrolling-text-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding-inline: 30%;
  /* background-color: #ff7d00 !important; */
  background-color: var(--light-orange);
  overflow: hidden;
  z-index: 1;
  position: relative;
}

/*.call-bar-text*/
.scrolling-text {
  font-family: "Poly", serif;
  color: white;
  font-size: 1.5rem;
  width: 80%;
  /* margin-left: 35%; */
  /* padding-right: 15px; */
  display: inline-block;
  white-space: nowrap;
  animation: scrolling 10s linear infinite;
}

/*.call-bar-num*/
.scrolling-text-num {
  text-decoration: none;
  color: white;
  /* font-family: "Bevan", cursive; */
  /* font-family: "Poly", serif; */
  font-family: "BIZ UDMincho", serif;
  /* font-family: 'Castoro Titling', cursive;
  font-family: 'Libre Caslon Display', serif;
  font-family: 'Lobster Two', cursive;
  font-family: 'Xanh Mono', monospace; */
  font-size: 1.5rem;
  font-weight: 700;
  display: inline-block;
  white-space: nowrap;
  /* animation: scrolling 10s linear infinite; */
}

.scrolling-text:hover {
  animation-play-state: paused;
}

.scrolling-text-num:hover {
  text-decoration: none;
  color: #ffecd1;
  transform: scale(1.1);
}

@keyframes scrolling {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.landing-parent {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  font-weight: 700;
  background-image: url("./img/tree-path.jpg");
}

.quote-container {
  border: 3px black solid;
  font-family: "BIZ UDMincho", serif;
  font-size: 1.5rem;
  background-color: #ffecd1ac;
  width: 60%;
  margin: auto;
}

.quote {
  width: 95%;
  height: auto;
  transform: rotateY(20deg);
  padding: 10px;
}

/* FOOTER STYLES */

.footer-container {
  display: flex;
  width: 100%;
  margin-bottom: 0;
  height: 125px;
  background-color: var(--green-darker);
  align-items: center;
  justify-content: space-evenly;
  padding-inline: 20px;
}

.footer-left {
  display: flex;
  flex-direction: column;
  color: black;
  font-size: 0.6rem;
}

.footer-mid {
  width: 70%;
  font-size: 1.2rem;
  font-family: "Libre Caslon Display", serif;
  color: black;
}

.attribute {
  font-size: 1.4rem;
  font-family: "Libre Caslon Display", serif;
  text-decoration: none;
  color: var(--accent-yellow);
  text-shadow: black 1px 2px 2px;
}

.attribute:hover {
  color: var(--tan);
  text-decoration: none;
}

.footer-right {
  margin-right: 0;
  font-family: "BIZ UDMincho", serif;
  text-decoration: none;
}

.logo-footer {
  width: 120px;
  height: 60px;
}

.footer-number {
  text-decoration: none;
  color: #ffecd1;
  font-size: 1.5rem;
}

.footer-number:hover {
  cursor: pointer;
  text-decoration: none;
  color: white;
  text-shadow: var(--light-orange) 2px 2px 2px;
}

.contact-box {
  background-color: var(--teal) !important;
}

.contact-title {
  font-size: 1.5rem;
  font-family: "BIZ UDMincho", serif;
  /* color: #ffffff !important; */
  color: var(--accent-yellow) !important;
}

.contact-info {
  font-family: "BIZ UDMincho", serif;
  font-size: 1.5rem;
  text-decoration-color: #ffffff !important;
  color: #000000;
  background-color: var(--tan);
}

.btn-btn-secondary {
  /* background-color: #ffecd1; */
  background-color: var(--accent-yellow) !important;
  font-family: "BIZ UDMincho", serif;
}

/* MOBILE STYLES */
.landing-info {
  padding-left: 10px;
  padding-right: 10px;
}

.call-button {
  border: black 2px solid;
  background-color: var(--light-orange);
}

.phone-number {
  text-decoration: none;
  color: #ffecd1;
}

.phone-number:hover {
  text-decoration: none;
  color: #ffecd1;
}

/* Accordion */

.demo-title {
  font-size: 2.5rem;
  padding: 10px;
  font-weight: 1000;
  font-family: "BIZ UDMincho", serif;
}

.accordion-parent {
  width: 90%;
  height: 120vh;
  margin: auto;
  padding-block: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.accordion-container {
  margin: auto;
  z-index: 100;
  margin-top: 20px;
  padding-top: 60px;
}

.accordion-item {
  width: 100%;
}

.accordion-item-header {
  width: 100%;
}

.accordion-button {
  background-color: var(--teal) !important;
  font-family: "BIZ UDMincho", serif;
  color: var(--accent-yellow) !important;
  font-size: 1.8rem !important;
}

.accordion-item-text,
.accordion-item-text p {
  font-family: "BIZ UDMincho", serif;
  font-size: 1.5rem;
  padding-inline: 30px;
  background-color: var(--tan);
  z-index: 100;
}

.line {
  width: 90%;
  margin: auto;
  border-bottom: 2px solid black;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.carousel {
  margin-top: 30px
}

.carousel-inner { 
  max-height: 900px; 
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(0.5); /* Adjust arrow color */
}

.carousel-control-prev,
.carousel-control-next {
  width: auto; /* Adjust arrow width if necessary */
}

/* MOBILE STYLES  */
@media only screen and (max-width: 900px) {
  .logoimg {
    width: 120px !important;
    height: auto;
  }

  .container {
    flex-wrap: nowrap !important;
    padding-top: 10px
  }

  .nav-main {
    flex-direction: column;
    justify-content: center;
  }

  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    flex-direction: column !important;
  }

  .navbar-toggler {
    right: 0 !important;
    /* background-color: var(--light-orange) !important; */
    background-color: #ffecd1ac !important
  }

  .logo {
    margin-top: 10px;
    top:0;
    left: 0 !important
  }

  .scrolling-text {
    animation: none;
    padding-right: 0px;
    margin: auto;
    font-size: 1rem;
    text-align: center;
  }

  .scrolling-text-num {
    font-size: 1rem;
  }

.scrolling-text-container {
  padding-inline: 0px;
}

.link {
  width: 150px;
  margin-right: 0px;
}

.landing-parent {
  display: block;
  height:100vh
}

.quote-container {
  margin-top: 20%
}

.footer-container {
  z-index: 1 !important;
}

.footer-mid {
  font-size: .7rem;
  width: 80px;
}

.attribute {
  font-size: .8rem
}

.footer-number {
  font-size: 1rem
}

.footer-right {
  max-width: 120px;
}

.footer-left {
  max-width: 120px;
  align-items: center;
  justify-content: center;
}

.logo-footer {
  height: 30px;
  width: 80px;
}
.accordion-body{
  z-index: 100 !important;
}

.accordion-body p {
  font-size: 1.2rem;
}

.accordion-parent {
  margin-top: 0px !important
}
.accordion-container {
  margin-top: 0px;
  padding-top: 20px
}

.demo-title {
  font-size: 1.8rem
}

.coming-soon-header {
  font-size: 1.9rem;
  height: auto
}


}
